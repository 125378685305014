<template>
  <ListPage
    :gridData="filteredData"
    :gridConfig="gridConfig"
    :filters="filters"
    :customListClass="
      ['admin', 'manager', 'supercustomer', 'lawyer'].includes(
        $store.state.AppActiveUser.role
      )
        ? 'text-sm'
        : ''
    "
    v-if="isReady"
  >
    <template v-slot:buttons="scope">
      <vs-button
        color="primary"
        type="filled"
        class="mb-5 ml-auto float-right"
        :to="{ name: 'main-cases-new' }"
      >
        {{ $t(`cases.buttons.new`) }}
      </vs-button>
      <div
        class="m-3 cursor-pointer inline float-right"
        @click="removeFilters"
        v-if="Object.keys(appliedFilters).some((x) => !!appliedFilters[x])"
      >
        <feather-icon icon="XIcon" svgClasses="w-6 h-6 text-danger" />
      </div>
    </template>
  </ListPage>
  <div class="custom-absolute-loading" v-else>
    <div class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ListPage from "@/components/penal-retail-helpers/ListPage";

const NEW_CASE_STATUS_ID = "mjkcEWDfWPAdPsaXmRB1"; //ID de estado de caso "nuevo"
import caseStatuses from "@/data/cases/lists/caseStatuses.json";
import penalTypes from "@/data/cases/lists/penalTypes.json";

import { db } from "@/firebase/firebaseConfig";

let collectionName = "cases";

export default {
  components: {
    ListPage,
  },
  computed: {
    ...mapGetters([
      "allCustomers",
      "allEstablishments",
      "allLawyers",
      "allCases",
      "allFilters",
    ]),
    isReady() {
      return (
        this.allCases &&
        this.allCustomers &&
        this.allEstablishments &&
        this.allFilters &&
        this.filteredData
      );
    },
    appliedFilters() {
      const defaultFilters = {
        customer: null,
        company: null,
        establishment: null,
        lawyer: null,
        penalType: null,
        caseStatus: null,
      };

      return this.allFilters.find((x) => x.id === "cases") || defaultFilters;
    },

    isCustomerUser() {
      return ["customer", "supercustomer"].includes(
        this.$store.state.AppActiveUser.role
      );
    },
    penalCustomers() {
      return this.allCustomers.filter((c) =>
        c.casesTypes.map((c) => c.id).includes("penal")
      );
    },
    establishments() {
      if (this.appliedFilters.customer) {
        return this.allEstablishments
          .filter((x) => x.customer.id === this.appliedFilters.customer.id)
          .map((x) => {
            return x.id === "empresa"
              ? {
                  ...x,
                  id: x.id,
                  alias: this.$t("establishments.labels.wholeCompany"),
                }
              : x;
          });
      } else if (this.isCustomerUser) {
        return this.allEstablishments.map((x) => {
          return x.id === "empresa"
            ? {
                ...x,
                id: x.id,
                alias: this.$t("establishments.labels.wholeCompany"),
              }
            : x;
        });
      } else {
        return [];
      }
    },

    filteredData() {
      let findLawyer = (lawyerId) => {
        let lawyer = this.allLawyers.find((l) => l.id === lawyerId);
        if (lawyer) {
          return { id: lawyer.id, alias: lawyer.alias };
        } else {
          return null;
        }
      };

      let findCustomer = (customerId) => {
        return this.allCustomers.find((c) => c.id === customerId);
      };

      let findEstablishment = (establishmentId) => {
        return this.allEstablishments.find((c) => c.id === establishmentId);
      };

      let filteredData = [].concat(
        this.allCases.map((x) => ({ id: x.id, ...x }))
      );

      for (let c of filteredData) {
        let establishment = c.establishment
          ? findEstablishment(c.establishment.id)
          : null;

        //Agregamos los datos del código y localidad de la tienda
        if (establishment) {
          c.establishmentCode = establishment.code;
          c.establishmentCity = establishment.city;
        }

        if (!c.lawyer) {
          let customer = c.customer ? findCustomer(c.customer.id) : null;
          let lawyerId =
            establishment && establishment.lawyer
              ? establishment.lawyer.id
              : customer && customer.lawyer
              ? customer.lawyer.id
              : null;
          if (lawyerId) {
            c.lawyer = findLawyer(lawyerId);
          }
        }

        if (!c.caseStatus) {
          let newStatus = caseStatuses.find((s) => s.id === NEW_CASE_STATUS_ID);
          c.caseStatus = newStatus;
        }
      }

      [
        "customer",
        "company",
        "establishment",
        "lawyer",
        "penalType",
        "caseStatus",
      ].forEach((a) => {
        if (this.appliedFilters[a]) {
          if (a == "penalType") {
            filteredData = filteredData.filter((c) => {
              if (c[a]) {
                let values = c[a];
                if (!Array.isArray(values)) {
                  values = [values];
                }
                return (
                  values.map((v) => v.id).indexOf(this.appliedFilters[a].id) >=
                  0
                );
              } else {
                return false;
              }
            });
          } else if (a === "company") {
            const companyEstablishments = this.allEstablishments
              .filter((x) => (x.company || {}).id === this.appliedFilters[a].id)
              .map((x) => x.id);
            filteredData = filteredData.filter(
              (c) =>
                c.establishment &&
                companyEstablishments.includes(c.establishment.id)
            );
          } else {
            filteredData = filteredData.filter(
              (c) => c[a] && c[a].id === this.appliedFilters[a].id
            );
          }
        }
      });

      return filteredData;
    },
    filters() {
      let filters = [];
      filters.push({
        size: "1/4",
        mobileSize: "1/2",
        extraClasses: "mb-5",
        search: true,
      });

      const canFilterByCustomer = ["admin", "manager", "lawyer"].includes(
        this.$store.state.AppActiveUser.role
      );
      if (canFilterByCustomer) {
        filters.push({
          size: "1/4",
          mobileSize: "1/2",
          select: true,
          placeholder: `${collectionName}.placeholders.customer`,
          label: "alias",
          clearable: true,
          value: this.appliedFilters.customer,
          onChange: (e) => {
            this.updateFilters("customer", e);
          },
          options: () => this.penalCustomers,
        });
      }
      if (this.companies.length) {
        filters.push({
          size: "1/4",
          mobileSize: "1/2",
          select: true,
          placeholder: `${collectionName}.placeholders.company`,
          label: "alias",
          clearable: true,
          value: this.appliedFilters.company,
          onChange: (e) => {
            this.updateFilters("company", e);
          },
          options: () => this.companies,
        });
      }
      filters.push({
        size: "1/4",
        mobileSize: "1/2",
        select: true,
        placeholder: `${collectionName}.placeholders.establishment`,
        label: "alias",
        clearable: true,
        value: this.appliedFilters.establishment,
        onChange: (e) => {
          this.updateFilters("establishment", e);
        },
        disabled: !this.isCustomerUser && !this.appliedFilters.customer,
        options: () => this.establishments,
      });
      filters.push({
        size: "1/4",
        mobileSize: "1/2",
        select: true,
        placeholder: `${collectionName}.placeholders.lawyer`,
        label: "alias",
        clearable: true,
        value: this.appliedFilters.lawyer,
        onChange: (e) => {
          this.updateFilters("lawyer", e);
        },
        options: () => this.allLawyers,
      });
      filters.push({
        size: "1/4",
        mobileSize: "1/2",
        enum: true,
        enumType: "penalTypes",
        placeholder: `${collectionName}.placeholders.penalType`,
        label: "alias",
        clearable: true,
        value: this.appliedFilters.penalType,
        onChange: (e) => {
          this.updateFilters("penalType", e);
        },
        options: () => penalTypes,
      });
      filters.push({
        size: "1/4",
        mobileSize: "1/2",
        enum: true,
        enumType: "caseStatuses",
        placeholder: `${collectionName}.placeholders.caseStatus`,
        label: "alias",
        clearable: true,
        onChange: (e) => {
          this.updateFilters("caseStatus", e);
        },
        options: () => caseStatuses,
        value: this.appliedFilters.caseStatus,
      });

      // const model = { customer: null };
      // if (["supercustomer"].includes(this.$store.state.AppActiveUser.role)) {
      //   model.customer = this.$store.state.AppActiveUser.customer;
      // }
      filters.push({
        size: !canFilterByCustomer && !this.companies.length ? "3/4" : "1/2",
        mobileSize: "1/2",
        custom: true,
        extraClasses: "text-right",
        template: "buttons",
        data: {},
      });
      return filters;
    },
  },
  data() {
    let columns = [];

    columns.push({ field: "cid", filter: false, width: 50 });
    columns.push({
      field: "datetime",
      filter: false,
      width: 75,
      valueFormatter: (data) => {
        return (
          this.$moment(data.value.toDate()).format("DD-MM-YYYY HH:mm") || null
        );
      },
    });

    if (
      !["customer", "supercustomer"].includes(
        this.$store.state.AppActiveUser.role
      )
    ) {
      columns.push({
        filter: false,
        width: 75,
        field: "customer",
        valueGetter: (params) => {
          return params.data.customer.alias;
        },
        filterValueGetter: (params) => {
          return params.data.customer.id;
        },
      });
    }
    columns.push({
      field: "establishment",
      filter: false,
      width: 100,
      valueGetter: (params) => {
        return params.data.establishment.alias;
      },
      filterValueGetter: (params) => {
        return params.data.establishment.id;
      },
    });

    if (
      ["admin", "manager", "supercustomer", "lawyer"].includes(
        this.$store.state.AppActiveUser.role
      )
    ) {
      columns.push({ field: "establishmentCode", width: 100 });
      columns.push({ field: "establishmentCity", width: 100 });
    }
    columns.push({
      field: "lawyer",
      filter: false,
      width: 100,
      valueGetter: (params) => {
        return params.data.lawyer ? params.data.lawyer.alias : null;
      },
      filterValueGetter: (params) => {
        return params.data.lawyer ? params.data.lawyer.id : null;
      },
    });
    columns.push({
      field: "penalType",
      filter: false,
      width: 100,
      valueGetter: (params) => {
        if (params.data.penalType) {
          let myTypes = params.data.penalType;
          if (!Array.isArray(myTypes)) {
            myTypes = [myTypes];
          }
          return myTypes
            .map((p) => penalTypes.find((x) => x.id === p.id))
            .map((p) => this.$t(`enums.penalTypes.${p.key}`))
            .join("+");
        } else {
          return null;
        }
      },
      filterValueGetter: (params) => {
        if (params.data.penalType) {
          let myTypes = params.data.penalType;
          if (!Array.isArray(myTypes)) {
            myTypes = [myTypes];
          }
          return myTypes.map((p) => p.id);
        } else {
          return null;
        }
      },
    });
    columns.push({
      field: "trialDatetime",
      filter: false,
      width: 75,
      valueFormatter: (data) => {
        return data.value
          ? this.$moment(data.value.toDate()).format("DD-MM-YYYY HH:mm")
          : null;
      },
    });
    columns.push({
      field: "caseStatus",
      filter: false,
      width: 50,
      filterValueGetter: (params) => {
        return params.data.caseStatus ? params.data.caseStatus.id : null;
      },
      cellRendererFramework: "CellRendererStatus",
    });

    columns = columns.map((c) => ({
      ...c,
      headerName:
        c.field === "caseStatus"
          ? ""
          : this.$t(`${collectionName}.columns.${c.field}`),
    }));

    return {
      gridConfig: {
        components: {},
        gridOptions: {},
        columnDefs: columns,
        defaultColDef: {
          sortable: true,
          resizable: true,
        },
        onRowClicked: this.onRowClicked,
      },
      companies: [],
      saveFiltersTimeout: null,
    };
  },
  created() {
    if (this.$store.state.AppActiveUser.role === "supercustomer") {
      this.$bind(
        "companies",
        db
          .collection(
            `customers/${this.$store.state.AppActiveUser.customer.id}/companies`
          )
          .where("deleted", "==", false),
        { wait: true }
      );
    }
  },
  methods: {
    updateFilters(prop, realValue) {
      let value =
        realValue &&
        ["customer", "company", "establishment", "lawyer"].includes(prop)
          ? { id: realValue.id, alias: realValue.alias }
          : realValue;
      let newFilter = { ...this.appliedFilters };

      let changed = false;
      if (value !== newFilter[prop]) {
        changed = true;
        newFilter[prop] = value;
      }

      if (changed) {
        //Hubo cambios, debemos salvar....
        (this.allFilters.find((x) => x.id === "cases") || {})[prop] = value;

        //Primero debemos ver si vamos a borrar el filtrado
        clearTimeout(this.saveFiltersTimeout);
        this.saveFiltersTimeout = setTimeout(() => {
          let mustRemove = Object.keys(newFilter).every(
            (key) => !newFilter[key]
          );

          if (mustRemove) {
            console.log("DEBEMOS BORRAR EL FILTRADO: ", newFilter);
            this.removeFilters();
          } else {
            console.log("DEBEMOS ACTUALIZAR EL FILTRADO: ", newFilter);
            db.collection(
              `users/${this.$store.state.AppActiveUser.uid}/filters`
            )
              .doc("cases")
              .set(newFilter);
          }
        });
      }
    },
    removeFilters() {
      db.collection(`users/${this.$store.state.AppActiveUser.uid}/filters`)
        .doc("cases")
        .delete();
    },

    onRowClicked(params) {
      this.$router
        .push(`/main/${collectionName}/${params.data.id}`)
        .catch(() => {});
    },
  },
};
</script>
